export const assets = [
  "https://ordinals.com/inscription/f598efdb2fdd6630d5d87640b63e75985b2372a04c5dae9eb47f671624a81f17i0",
  "https://ordinals.com/inscription/b453bae631f103d52ec4c2a0d8b630c303e8d6afbb6ca834e97f457274ceb48fi0",
  "https://ordinals.com/inscription/a6f8478a1a9e50e60fd56716e6df0c999e392d69f207578ffd968d2447878598i0",
  "https://ordinals.com/inscription/0316d67705c5da9892b9aedecda6c2a2222a23129d57b0989c19da490a29312fi0",
  "https://ordinals.com/inscription/28e13d7445784e2be57aecd9b933b80930bb1ac4169835af4a0bb671b540949ei0",
  "https://ordinals.com/inscription/3051c5c44d4c35087756e3480fc2c71d1ec304348ec76cbcaafcffe6a3049014i0",
  "https://ordinals.com/inscription/2c397b008e2c202c883601448bd422176ecabf850a332a375a30ae17c38d0675i0",
  "https://ordinals.com/inscription/0d9cfc0f9a4ac08548f0daf7ec487ca120d2e100b8c4a831604eace280a238d2i0",
  "https://ordinals.com/inscription/8fc920a51b894651974df3018069058ab3cd3bc7bf34de2d05f3b7fa4b5f264ai0",
  "https://ordinals.com/inscription/ab5c8268bf0fb0e6e49361f26d53d75f4e0e9c6bbcf37ce919b408b83bf82aaei0",
  "https://ordinals.com/inscription/0a6714b1ac0deaa4b59d8e9b48f50919b8369d2c1633b5cc00f63351b8531feei0",
  "https://ordinals.com/inscription/f606f4b4d3425cbf06f7bfedd70e1229b1650fe1107b823703dba077b864b62bi0",
  "https://ordinals.com/inscription/a5007e81f177956e78a9e5a65aef68a0e33a2f9cf60a0eef3117a680e347df8di0",
  "https://ordinals.com/inscription/e9d0443c067d2ef2111e5a67ea29bc3c7b103894e73048b4730d2d11e217ad6di0",
  "https://ordinals.com/inscription/afed021a5d4efcc8bf44c8c185135374bca64a51f0b25eee4d6423977528ed77i0",
  "https://ordinals.com/inscription/eca6a655759640de6903bb206d493e62dcd621d3efaac7761d12cca60857a304i0",
  "https://ordinals.com/inscription/639c8ca07f010565e34a18d105297a01a23667a52466647b85f29974acc0e79bi0",
  "https://ordinals.com/inscription/4c7a78cc86f08bd3d72f163a47d2e5a1814ce0957db6a8f2ae6d4b3828553743i0",
  "https://ordinals.com/inscription/9b01166194144b9d73a1858db8ecedac49a8c46d554d5defd2ec54395e93da9di0",
  "https://ordinals.com/inscription/d27f544ae043cf80908dc05ce97ba1b90c3ed63044d4db1f3ca1f3202c6b767ei0",
  "https://ordinals.com/inscription/aca357d678241f40aacea4f7d93c26569dec2795bad84b5cf48ec1ab1bb9f3e3i0",
  "https://ordinals.com/inscription/ef9b1cd663f478b90e21f987ab76380b83024ccf0abc157dc4f619409fed614bi0",
  "https://ordinals.com/inscription/d90db5464f000b2f432a7aa2d44aa4b02c792233d0703b595f76f027fa578c64i0",
  "https://ordinals.com/inscription/aa29e1720846fa4d94902f32d7a921551394436f013172497594fcaf0bf3a4b6i0",
  "https://ordinals.com/inscription/c0af9a7ae1cd093c3f578f4233fcbb1e1cce7bff739d72a2b21f9b4b76ac3836i0",
  "https://ordinals.com/inscription/d5b4a514dc4d99914e7ae3828bb5101f742d92f99e9d328eb3e74e5cc3e03240i0",
  "https://ordinals.com/inscription/4ad65758c75dc333c7a0dd4901954ae549aa871593924b76660fa2665bba3a42i0",
  "https://ordinals.com/inscription/52545c058f03ac8b9c0e580a0fe8ffb2a35c126b258b416bb22e58b5e287cca6i0",
  "https://ordinals.com/inscription/1845cc960854ad2da0bf64b2a30a447e33fa08004e915f23d35b2eecfc4a1cd5i0",
  "https://ordinals.com/inscription/408a284b310a16d234db62b28e890798d79be529e211bed820913b59b4b2052ci0",
  "https://ordinals.com/inscription/3055bb9e6b07219529cf2b9a7693ad4014d9f105847dbe19f14ad52af2043880i0",
  "https://ordinals.com/inscription/7d0843feed7ea9af86215067cf3d0ed3f1521e5797385b11fc33d5114f0993e4i0",
  "https://ordinals.com/inscription/cd11fdaa6af34f6d2d9f15806d6e781f1e76fe452c146669b0eca283c175493di0",
  "https://ordinals.com/inscription/5ee28566fec1654ada2c78e28caf1224a4c06f38128017728266534365e942bci0",
  "https://ordinals.com/inscription/4d71621a8ef77b71bea64e06cedfbed0afef83689ba1c78c32d096dd4efccddai0",
  "https://ordinals.com/inscription/3277432c28986551b05aa5cfe575b0c210ce46ba5fa52a631823ec5dd8db1f88i0",
  "https://ordinals.com/inscription/a196d1af1aee36adc5328033670fff276bf4c0f23c1d923f42db3d7ec42d3f9ai0",
  "https://ordinals.com/inscription/2d7de7fbe32202651c82434739627d2b8565d82120850f3d4edfd45f62b4f8dai0",
  "https://ordinals.com/inscription/3302c60dfc51ea76d7c226d81b2c578419547b20348de751c3c9d5a144cbba31i0",
  "https://ordinals.com/inscription/3a6cf9ca754b781a5941f1667845a7115b43188214dc1fb3786fbdc9ac33413ei0",
  "https://ordinals.com/inscription/9ae577b11ff1d16b3c3929f68a1aa9bc8e1883f19299e07caec64134ef086f73i0",
  "https://ordinals.com/inscription/e43532f0aa58a9cf5acbb63446ea270447c6e563520fbdeb4ba565ccb820955ai0",
  "https://ordinals.com/inscription/b4838a0efb970ebc956fb1e68645beb6d05497b15c900df413bad96c3b7c10b1i0",
  "https://ordinals.com/inscription/65817aa0ca9d496c4261577da3f21d4f3eaad6baec0982b3db2a767a2f07a9fai0",
  "https://ordinals.com/inscription/035c66407bdd2ceb7af25372dcabc61baf8052490fa19f2fe078b59a8d696473i0",
  "https://ordinals.com/inscription/011debb84c09c0f34ad2ad9d4b57b58a677ccfb6563be9978e06a080d28784e1i0",
  "https://ordinals.com/inscription/5298412688e9b9da2d79743379dc429984eb642a3b86cd079bc999992fea97e3i0",
  "https://ordinals.com/inscription/7121b5dccbf404d8251cdcdf4c2d2bfb08a3cea15449a64ba76a7520f4fc9759i0",
  "https://ordinals.com/inscription/105b075545adc81b3e91597fd8dfd4674a019146c3de8dd01deeb79880c18ab7i0",
  "https://ordinals.com/inscription/642d9137a87b398957125cd567ae5d30a14ea00a8e731aff3e9648b49ef5beeai0",
  "https://ordinals.com/inscription/950a5f092c69a6c7428f5e7f28792ba78c0c70dd56d7b1b8079b5a2dadd84005i0",
  "https://ordinals.com/inscription/b9fd92092f8afbca3d4f4af0ba4303a3c9ac3b12f2a525983c67e2bfc2605c19i0",
  "https://ordinals.com/inscription/629dc5d9394f5c235329a1d7ccb7547282ed5c659e7b7c0be29db3ea317f239bi0",
  "https://ordinals.com/inscription/66a6d8943a30cca9661cd0bbe66c96732ef89b954c4be81c626f605fe4b37307i0",
  "https://ordinals.com/inscription/65e01b8a0c74224fd8516db5ad34e3640281892b370913c6f015325e6f5c611ai0",
  "https://ordinals.com/inscription/acdc624566ac2acdc1f92777fa71fc3f0e3c916dd3a53ce26d8249535f0400d9i0",
  "https://ordinals.com/inscription/13fd290e50c7f56d4534afbc08d9d4839265fec84ca8612f117db2cd538b3142i0",
  "https://ordinals.com/inscription/2fa79b8b12058a8beeedb44b5cdb335d7c51f53b0aec6674714e2c9fa1b43d51i0",
  "https://ordinals.com/inscription/2a7995a953cf3df1d5120c77db2c2e6ab2aab702b7a38925f77c1616476444e3i0",
  "https://ordinals.com/inscription/1d8f1538d8c683f509fbf14aef57d2d3fa92e76a372809544c3dd8ebfc785454i0",
  "https://ordinals.com/inscription/cbee35dcceba3d867ec685d012448e7340f8fc08f49741fa1e2647e7d3d72583i0",
  "https://ordinals.com/inscription/a7f070d610c8c7f040ae180eebcd58634867e583269c3cdea254115bea2162c9i0",
  "https://ordinals.com/inscription/023101a1afd399f286f4b2325adb14905e8346628a38c3183bd1e1ff2ed6f511i0",
  "https://ordinals.com/inscription/872b55c63186d082726673f339ce9af89c408f7c9d399b590ae0eb3283e9eb38i0",
  "https://ordinals.com/inscription/ebdb9667add5a83de2f7ec339e1f2c945c81025598d749eacfd8dba800f526bdi0",
  "https://ordinals.com/inscription/a5b89ec6492c9f3b2f6bdb99ae63ea34725cc2b4bda494892e602c7728bef07di0",
  "https://ordinals.com/inscription/be7a0fca9a864560b5ebabb773a48b0421414e4fc26e3be72a68fabe05332185i0",
  "https://ordinals.com/inscription/4be1ff7a99edbea4343ca1a7aed20ca13e9d71cf7211392b3e83fa815bef0aabi0",
  "https://ordinals.com/inscription/6c8f4d649e3bd62df5a1d49d7cc8b22358a26b89f5aa257f114fe69207394c68i0",
  "https://ordinals.com/inscription/f41fb8b20e645cf0100560ef5d3756a64d306a82ff1a9b9d3137eebf815c688di0",
  "https://ordinals.com/inscription/3052957e500d0679262c79962d27bef5ebe16f937c213448de2a2686fdbc26f9i0",
  "https://ordinals.com/inscription/e7e632a3e55566320592964aba790583b5255dd33c79e0a1a221b894c2713549i0",
  "https://ordinals.com/inscription/9d359a091103de75fc0bafbe726d92ea9595606c7ec355f8048a2ef948193b7bi0",
  "https://ordinals.com/inscription/e54aec10c5dcd646a3c3e551bda7e12e00f5405292eeabfbb56a3e2d3f9204fei0",
  "https://ordinals.com/inscription/8f38be6c98812dffb9698d30b2ad06e4f0df4c8c1bfdeecbaba4c1dc5b203f3fi0",
  "https://ordinals.com/inscription/0d2fbe71b14f300f068402c9ad72dce5fb69ed5fd2079e9e405b478c48bca982i0",
  "https://ordinals.com/inscription/83d5e63d50850348cee0acab6fb6d5dcec4fef1187098e369eafdde25ff0cceci0",
  "https://ordinals.com/inscription/74d5efc3d14f554686b2637884dca06ad8aa0aef1fb8e308955fa9b4a03dae2bi0",
  "https://ordinals.com/inscription/0d48de516a8dbdbcd21de2686a0d026c6d18893c5dab2f0ed5c6a524fd41bc33i0",
  "https://ordinals.com/inscription/2cf505c61c377ae838a33b28e654924b2bd2dc6e34f50bb80376e05fbf4ce989i0",
  "https://ordinals.com/inscription/b55a18802130bb2394991d9686a0559a6b1f27faa288b7ff6f0e630e636e8b7di0",
  "https://ordinals.com/inscription/19bea06e79e713989cf11d851ae8ac3b831cf2e0ff9ef234c17657af1b2fbaa7i0",
  "https://ordinals.com/inscription/f1e0e94496f3041f61496634f31d2924f3b1369422937484e66bcd0e26cebfc3i0",
  "https://ordinals.com/inscription/eee60d8ffeb5e56233a6021925494516895daa60b07fe9c17019d494cb5e5856i0",
  "https://ordinals.com/inscription/99aae7754eee4bcf455734543181aada677ef28e16c5b108acf29a2f2186926bi0",
  "https://ordinals.com/inscription/7cc6115eccb1a2d81257ff316c5853b06a8f0dce7aefdce5f221982df79badc9i0",
  "https://ordinals.com/inscription/a635e2a08172a59a13566f5a3eb1e68dacb170ff38247fec4bb16373bf02b66ai0",
  "https://ordinals.com/inscription/4c505dab2745957cbac666a5401f9c3ce344597662f68d06aa7837c11d3bbd74i0",
  "https://ordinals.com/inscription/3916f782e1d39e6e0882e66e347f4634f4ae481762520cf388965a5b324d41dei0",
  "https://ordinals.com/inscription/91b72a3ed5d43ca9b5b8d788b2b8c37fe6bb07b17d22e5bb72b42f1dd6c26439i0",
  "https://ordinals.com/inscription/675c95f331fcb780eb6ed59ede9ccd5b3df25087ad8319f99412e4d2a6e6c098i0",
  "https://ordinals.com/inscription/888ae6e0f3414170cc57e9ee6a56f7d19ecb21392a3fc57e0ed83c5feb2a50f5i0",
  "https://ordinals.com/inscription/3af5efc7e400d219ae07aa21135e082d085553ff20cb4f7377e36ed463026a5fi0",
  "https://ordinals.com/inscription/733c3b9edd9139ed8441da0577c904165184ead4496401d5772da84759e1147di0",
  "https://ordinals.com/inscription/e04d5e79b0ec0da8a0ec2f521066dfae1c46896ef813ce0264082e430bc4d506i0",
  "https://ordinals.com/inscription/992fd84216175156285ec7f22003e9579f0de16639f78963212146dcf916a3f5i0",
  "https://ordinals.com/inscription/355ba4f42d54715204c173bf93b93ff2e3520d89b5c70795b117fef61d029185i0",
  "https://ordinals.com/inscription/853a2e818a28b97557c34a0ee9b3aa02901162e045b21a85ba15af49b8751ea0i0",
  "https://ordinals.com/inscription/bda48c39aa888039080207447344d309ee66a3e7c58b29bfc4c0a4b1007d6bd1i0",
  "https://ordinals.com/inscription/250746bd63b9887138a2d03fab7a3ba3eba0dc24249ff825aaae2f5302056a5bi0",
];
