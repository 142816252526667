import React from "react";
import { Card } from "antd";
const { Meta } = Card;
const CardDisplay = ({ cover, metaTitle, metaDescription, className }) => (
  <Card
    hoverable
    style={{
      width: "120px",
      backgroundColor: "black",
      border: "1px solid black",
      padding: "0px !important",
    }}
    cover={cover}
    className={className}
  >
    <Meta title={metaTitle} description={metaDescription} />
  </Card>
);
export default CardDisplay;
