import { Col, Flex, Row } from "antd";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import { FaBook } from "react-icons/fa";

import magicEden from "../../assets/magiceden.svg";
import arabpunklogo from "../../assets/logo.png";

const MainHeader = () => {
  const CONTENT_API = process.env.REACT_APP_CONTENT_API;
  
  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <img
            src={arabpunklogo}
            alt="noimg"
            width={"50px"}
          />
        </Col>
        <Col>
          <Flex gap={30}>
            
            <a href="https://magiceden.io/ordinals/marketplace/arabpunks" target="_"><img src={magicEden} alt="noimg" width="48px" /></a>
            <a href="https://bit.ly/discord-arabpunks" target="_"><FaDiscord color="violet" size={30} className="pointer " /></a>
            <a href="https://twitter.com/arabpunksxyz" target="_"><FaXTwitter color="white" size={30} className="pointer" /></a>
            <a href="https://bit.ly/arabpunks-litepaper" target="_" alt="ArabPunks Litepaper v1.0"><FaBook color="violet" size={28} className="pointer " /></a>

          </Flex>
        </Col>
      </Row>
    </>
  );
};
export default MainHeader;
