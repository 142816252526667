import { Col, Row } from "antd";

const CollaspeChildren = () => {
  return (
    <>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">INTRODUCTION</li>
            </ul>
          </Row>
          <Row>
            <ul>
              <li className="arab-font color-white font-size-28">
                March 6, 2023, marks a significant milestone for the Arab punk movement as Arabs begin joining Bitcoin nodes in droves. With inscriptions ranging from 325,337 to 328,679, a dedicated group of 100 Arabs makes a steadfast commitment to remain. As excitement mounts and anticipation builds, the rallying cry of "WEN MOON" echoes through the community, fueling their determination. With unwavering resolve and boundless optimism, Arabs stand united, unstoppable in their quest to embrace the future of Bitcoin and beyond.
              </li>
            </ul>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">NARRATION</li>
            </ul>
          </Row>
          <Row>
          <ul>
            <li className="arab-font color-white font-size-28">
                <p><strong>In the late 2030s, amidst the rapid rise of Arab Punks, powered by cutting-edge AI algorithms:</strong></p>
                <ul>
                    <li className="arab-font color-white font-size-28">Two factions, the Guardians of the Oasis and the Techno Bedouins, engage in a fierce struggle for supremacy in controlling Bitcoin.</li>
                    <li className="arab-font color-white font-size-28">Utilizing advanced computational capabilities, they employ sophisticated strategies to outmaneuver each other in the digital battleground.</li>
                </ul>
            </li>
            <li className="arab-font color-white font-size-28">
                <p><strong>Amidst the chaos, a superintelligent AI entity named Al-Nujoom (The Stars) emerges:</strong></p>
                <ul>
                    <li className="arab-font color-white font-size-28">Crafted by the most brilliant minds of the Arab Punk movement.</li>
                    <li className="arab-font color-white font-size-28">Programmed to embody the ideals of both factions and driven by collective aspirations, Al-Nujoom seeks to shape Bitcoin's destiny.</li>
                </ul>
            </li>
            <li className="arab-font color-white font-size-28">
                <p><strong>To stop the impending war and gain control of Bitcoin, $DHM, a Rune Token infused with ancient power, is sent back in time:</strong></p>
                <ul>
                    <li className="arab-font color-white font-size-28">Its purpose is to build an army of supporters, unlocking hidden potentials and forging unbreakable bonds.</li>
                    <li className="arab-font color-white font-size-28">$DHM seeks allies across ages, uniting warriors to stand against the conflict and shape Bitcoin's destiny.</li>
                </ul>
            </li>
            <li className="arab-font color-white font-size-28">
                <p><strong>Amidst the chaos, the memory of Satoshi's vision and the legacy of Hal Finney persist:</strong></p>
                <ul>
                    <li className="arab-font color-white font-size-28">Finney's untimely death serves as a poignant reminder of life's fragility and the relentless march of time.</li>
                    <li className="arab-font color-white font-size-28">His legacy inspires both factions to honor his vision, striving for a future where decentralization and empowerment endure.</li>
                </ul>
            </li>
        </ul>

          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">HOW DO MINING $DHM WORK?</li>
            </ul>
          </Row>
          <Row>
          <ul>
            <li className="arab-font color-white font-size-28">
                <p><strong>With each confirmation of a Bitcoin block, Each Arab Punk mining $DHM awards the holding wallet with an allocation of $DHM.</strong></p>
            </li>
            <li className="arab-font color-white font-size-28">
                <p>This allocation-per-block varies based on:</p>
                <ul>
                    <li className="arab-font color-white font-size-28">The wallet’s assortment of $DHM.</li>
                    <li className="arab-font color-white font-size-28">Any applied bonuses.</li>
                    <li className="arab-font color-white font-size-28">The individual Core weight of each $DHM held.</li>
                </ul>
            </li>
          </ul>

          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">WHEN IS THE MINING PERIOD? </li>
            </ul>
          </Row>
          <Row>
          <ul>
            <li className="arab-font color-white font-size-28">The mining period starts on the block in which the Arab Punks as Ordinal is inscribed (this Ordinal you’re looking at).</li>
            <li className="arab-font color-white font-size-28">The mining period ends at block height 840,000 (the halvening/approximately April 19th, 2024).</li>
          </ul>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">
              HODLER BENEFITS IS ARAB GENERATIONS:
              </li>
            </ul>
          </Row>
          <Row>
          <ul>
            <li className="arab-font color-white font-size-28">
                <p>In the ever-expanding realm of ArabPunks generations, numbering up to 10,000, each with distinct traits and ancestral ties, a unique opportunity arises.</p>
            </li>
            <li className="arab-font color-white font-size-28">
                <p>A select group of 100 ArabPunks, steadfast in their lineage, perpetually holds access to the whitelist, entitling them to acquire at a discounted mint fee.</p>
            </li>
            <li className="arab-font color-white font-size-28">
                <p>As the blockchain marches forward, each block unveiling new possibilities, these privileged 100 remain guardians of tradition, entrusted with perpetuating the legacy of their ancestors.</p>
            </li>
            <li className="arab-font color-white font-size-28">
                <p>With each passing generation, the torch is passed, granting access to the next wave of ArabPunks to join the exclusive whitelist.</p>
            </li>
            <li className="arab-font color-white font-size-28">
                <p>Amidst the whirlwind of innovation and change, Bitcoin stands as a beacon of stability, its immutable blocks serving as pillars of security and trust.</p>
            </li>
            <li className="arab-font color-white font-size-28">
                <p>Within this landscape of decentralized empowerment, the ArabPunks generations carve their path, leveraging the power of ordinals and blockchain technology to shape their destiny.</p>
            </li>
          </ul>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">WHAT IS $DHM</li>
            </ul>
          </Row>
          <Row>
            <ul>
              <li className="arab-font color-white font-size-28">
              In March 2023, the Bitcoin blocks were captured by the Arab Punks from the nomads, and the Rune coin Dirham ($DHM) will be issued in Bitcoin using RUNE PROTOCOL.
              </li>
            </ul>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">TOKENOMICS OF $DHM</li>
            </ul>
          </Row>
          <Row>
            <ul>
              <li className="arab-font color-white font-size-28">
                RUNE TOKENS $DHM: 1,000,000,000 [allocation is variable and happens
                once per bitcoin block]
              </li>
            </ul>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">
                RUNES PROTOCOL INTRODUCTION
              </li>
            </ul>
          </Row>
          <Row>
            <ul>
              <li className="arab-font color-white font-size-28">
                Ordinals creator Casey Rodarmor proposed the new protocol in
                September 2023, pitching it as a better and simpler alternative
                to the experimental BRC-20 standard for fungible tokens. Runes
                are fungible tokens issued directly on Bitcoin using the Runes
                protocol.
              </li>
            </ul>
          </Row>
        </Col>
      </Row>
       <Row>
        <Col>
          <Row>
            <ul>
              <li className="arab-font color-red font-size-23">
                BEGIN YOUR JOURNEY
              </li>
            </ul>
          </Row>
          <Row>
            <ul>
              <li className="arab-font color-white font-size-28">
                You can reserve your ArabPunks inscriptions via Magic
                Eden.
              </li>
              <li className="arab-font color-white font-size-28">
              Each inscription comes with an Ordinal PFP, a RUNE RIG for mining $DHM
              </li>
              <li className="arab-font color-white font-size-28">
              Your inscriptions get delivered to your BTC taproot wallet address during the distribution period for each round.
              </li>
            </ul>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default CollaspeChildren;
