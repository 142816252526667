import { Collapse, Flex, Row, Typography } from "antd";
import React from "react";
import { assets } from "../../common";
import CardDisplay from "../../component/card";
import CollaspeChildren from "../collaspe";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import { useState } from "react";

const MainContent = () => {
  const { Text } = Typography;
  const [collapseOpen, setCollapseOpen] = useState([]);
  const CONTENT_API = process.env.REACT_APP_CONTENT_API;
  
  const genExtra = () => {
    return collapseOpen.length ? (
      <FiMinus color="white" size={25} className="mt-10" />
    ) : (
      <FiPlus color="white" size={25} className="mt-10" />
    );
  };

  const items = [
    {
      key: 1,
      label: (
        <Text className="color-white arab-font font-size-28">
          READ [LITEPAPER] V1.0
        </Text>
      ),
      children: <CollaspeChildren />,
      extra: genExtra(),
    },
  ];

  const onChange = (key) => {
    console.log(key);
    setCollapseOpen(key);
  };
  return (
    <>
      <Row justify={"center"}>
        <Text className="color-green arab-font font-size-50 mt-10">
          ARAB PUNKS
        </Text>
      </Row>
      <Row justify={"center"}>
        <Text className="color-white arab-font font-size-40 mt-10">
        The ArabPunks collection comprises 100 punks, with inscriptions dating as early as March 6, 2023. These punks are inscribed between 325,337 to 328,679 
        </Text>
      </Row>


      <Row justify={"center"}>
        <Collapse
          className="mt-10"
          items={items}
          style={{ width: "90%" }}
          onChange={onChange}
        />
      </Row>

      <Row justify={"center"}>
        <Text className="color-white arab-font font-size-40 mt-10">
          GALLERY
        </Text>
      </Row>

      <Flex justify="center" wrap="wrap" gap={30} className="ptb-30 ">
        {assets.map((data, index) => {
          const asset = data.split("/");
          const id = asset[4];
          return (
            <CardDisplay
              className={"card-hover"}
              key={`asset-${index}`}
              metaTitle={
                <>
                  <span className="black-bg color-white arab-font font-size-20">
                    PUNK #
                  </span>
                  <span className="black-bg color-white arab-font font-size-30">
                    {index + 1}
                  </span>
                </>
              }
              cover={
                <img
                  src={`${CONTENT_API}/content/${id}`}
                  alt="noimg"
                  className="black-bg"
                />
              }
            />
          );
        })}
      </Flex>
    </>
  );
};
export default MainContent;
