import React from "react";
import { Layout, Row } from "antd";
import MainHeader from "../container/header";
import MainContent from "../container/content";
const { Header, Footer, Content } = Layout;
const MainLayout = () => {
  return (
    <Layout>
      <Header className="header-style lightgrey-bg color-white">
        <MainHeader />
      </Header>
      <Content className="content-bg ">
        <MainContent />
      </Content>
      <Footer className="lightgrey-bg color-white">
        <Row
          justify={"center"}
          className=" font-size-28 arab-font"
        >{`Copyright © ${new Date().getFullYear()}`}</Row>
      </Footer>
    </Layout>
  );
};
export default MainLayout;
